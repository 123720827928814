import axios from 'axios'
import Pusher from 'pusher-js'
import { uniqBy as _uniqBy, isArray } from 'lodash'
import { format } from 'date-fns'
import { processGeojson, processRowObject } from 'kepler.gl/processors'
import { addDataToMap, layerConfigChange, layerVisConfigChange, removeDataset, interactionConfigChange, updateMap, removeLayer, layerTextLabelChange, layerVisualChannelConfigChange, addFilter, setFilter } from 'kepler.gl/actions'
import { setLayerSizeWithZoomLevel } from './layerActions'
import { COMPANY_DASHBOARD, ROUTE, TRACKER } from '../../App.config'

// Get Dashboard Analytics
export function getAnalytics() {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_ANALYTICS, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get Dashboard Analytics Within Time Range
export function getAnalyticsWithinTimeRange(timeRange) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_ANALYTICS_WITHIN_TIME_RANGE, { params: { start_time: timeRange.startTime, end_time: timeRange.endTime }, headers: { Authorization: 'Bearer ' + token }})
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Add New User
export function addNewUser(user) {
    const token = localStorage.getItem('token')
    
    return axios.post(COMPANY_DASHBOARD.ADD_USER_API, user, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Add Bulk New User [File Upload]
export function addBulkNewUser(users) {
    const token = localStorage.getItem('token')
    const company_id = localStorage.getItem('companyId')

    const data = new FormData()

    data.append('company_id', company_id)
    data.append('users', users)

    return axios.post(COMPANY_DASHBOARD.ADD_BULK_USER_API, data, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get All Usets
export function getAllUsers() {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_ALL_USERS_API, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            const users = res.data.data

            // Sort by Name
            users.sort((a, b) => {
                const nameA = a.name.toLowerCase()
                const nameB = b.name.toLowerCase()

                if(nameA < nameB) {
                    return -1

                } else if(nameA > nameB) {
                    return 1
                }

                return 0
            })

            return users
        })
        .catch(err => {
            throw err
        })
}

// Update User Information
export function updateUser(user) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.UPDATE_USER_API, { params: user, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Delete User Information
export function deleteUser(user) {
    const token = localStorage.getItem('token')

    return axios.delete(COMPANY_DASHBOARD.DELETE_USER_API + user, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Create New Group
export function createGroup(groupName) {
    const token = localStorage.getItem('token')

    return axios.post(COMPANY_DASHBOARD.CREATE_GROUP_API, { group_name: groupName }, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get All Groups
export function getAllGroups() {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_ALL_GROUPS_API, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            const groups = res.data.data

            // Sort by Name
            groups.sort((a, b) => {
                const nameA = a.group_name.toLowerCase()
                const nameB = b.group_name.toLowerCase()

                if(nameA < nameB) {
                    return -1

                } else if(nameA > nameB) {
                    return 1
                }

                return 0
            })

            return groups
        })
        .catch(err => {
            throw err
        })
}

// Get Group Users
export function getGroupUsers(groupId) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_GROUP_USERS_API, { params: { group_id: groupId }, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data.users.length > 0 ? res.data.users[0].local_users : []
        })
        .catch(err => {
            throw err
        })
}

// Assign User To Group
export function assignUserToGroup(userId, groupId) {
    const token = localStorage.getItem('token')
    // console.log(userId)

    return axios.put(COMPANY_DASHBOARD.ASSIGN_USER_TO_GROUP_API, { user_id: userId, group_id: groupId }, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get Company Settings
export function getCompanySettings() {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_COMPANY_SETTINGS_API, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Update Company Settings
export function updateCompanySettings(settings) {
    const token = localStorage.getItem('token')

    return axios.put(COMPANY_DASHBOARD.UPDATE_COMPANY_SETTINGS_API, settings, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get User Trips within Time Range
export function getUserTripsWithinTimeRange(tripParams) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_USER_TRIPS_WITHIN_TIME_RANGE_API, { params: tripParams, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get User Trips within Time Range TEST
export function getUserTripsWithinTimeRangeTest(tripParams) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_USER_TRIPS_WITHIN_TIME_RANGE_API_TEST, { params: tripParams, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get Active Trips
// Get User Trips within Time Range
export function getActiveUserTrips(tripParams) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_ACTIVE_TRIPS, { params: tripParams, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get User Trips within Time Range
export function getActiveUserTripsTest(tripParams) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_ACTIVE_TRIPS_TEST, { params: tripParams, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Add User Trips To Map
export function addUserTripsToMap(userTrips) {
    return dispatch => {
        // Convert to Route GeoJSON
        const routeGeojson = {
            type: 'FeatureCollection',
            features: userTrips.map(t => {
                const properties = {}
                Object.keys(t.trip_info).forEach(k => {                    
                    if (k !== 'path') {
                        properties[ k ] = t.trip_info[ k ]
                    }
                })

                return {
                    type: 'Feature',
                    properties,
                    geometry: t.trip_info.path.geo_json
                }
            })
        }                

        // // Points of Trips Data
        // const tripsRawData = userTrips.filter(u => u.trip_info.path.geo_json)    
        
        // const tripsDataPoints = !!tripsRawData && tripsRawData.length > 0 && tripsRawData.map(d => {
            
        //     const tripId = d.trip_info.trip_id
        //     const coordinates = d.trip_info.path.geo_json.coordinates            

        //     const filteredFeaturesArray = !!coordinates && coordinates.length > 0 && coordinates.map(item => ([
        //         {
        //             type: 'Feature',
        //             properties: {
        //                 trip_id: tripId,
        //                 endpoint_type: 'lineString_points',                        
        //                 longitude: item[0],
        //                 latitude: item[1],
        //                 lat_long: `${item[1]},${item[0]}`,
        //             },
        //             geometry: {
        //                 type: 'Point',
        //                 coordinates: item
        //             }
        //         }
        //     ])).flat()

        //     return filteredFeaturesArray
        // }).flat()


        // const newTripsDataPoints = !!tripsDataPoints && tripsDataPoints.length > 0 && {
        //     type: 'FeatureCollection',
        //     features: tripsDataPoints
        // }        

        // Generate Endpoints GeoJSON
        const endpointsGeojson = {
            type: 'FeatureCollection',
            features: userTrips.filter(u => u.trip_info.path.geo_json).map(t => ([
                {
                    type: 'Feature',
                    properties: {
                        trip_id: t.trip_info.trip_id,
                        endpoint_type: 'start',
                        longitude: t.trip_info.path.geo_json.coordinates[0][0],
                        latitude: t.trip_info.path.geo_json.coordinates[0][1],
                        lat_long: `${t.trip_info.path.geo_json.coordinates[0][1]},${t.trip_info.path.geo_json.coordinates[0][0]}`
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: t.trip_info.path.geo_json.coordinates[0]
                    }
                },
                {
                    type: 'Feature',
                    properties: {
                        trip_id: t.trip_info.trip_id,
                        endpoint_type: 'end',
                        longitude: t.trip_info.path.geo_json.coordinates[t.trip_info.path.geo_json.coordinates.length - 1][0],
                        latitude: t.trip_info.path.geo_json.coordinates[t.trip_info.path.geo_json.coordinates.length - 1][1],
                        lat_long: `${t.trip_info.path.geo_json.coordinates[t.trip_info.path.geo_json.coordinates.length - 1][1]},${t.trip_info.path.geo_json.coordinates[t.trip_info.path.geo_json.coordinates.length - 1][0]}`
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: t.trip_info.path.geo_json.coordinates[t.trip_info.path.geo_json.coordinates.length - 1]
                    }
                }
            ])).flat()
        }                

        dispatch( dispatchTripsToMap(routeGeojson, endpointsGeojson) )
    }
}

// Dispatch Trips LineString Features to Map
function dispatchTripsToMap(tripGeojson, endpointsGeojson) {
    return (dispatch, getState) => {
        // Clear Previous Route & Endpoints Dataset
        dispatch( removeDataset(ROUTE.DATA_ID) )
        dispatch( removeDataset(ROUTE.ENDPOINTS_DATA_ID) )

        // Build Datasets
        const tripDataInfo = { id: ROUTE.DATA_ID, label: ROUTE.DATA_LABEL }
        const tripData = processGeojson(tripGeojson)
        const tripDataset = { info: tripDataInfo, data: tripData }

        const endpointsDataInfo = { id: ROUTE.ENDPOINTS_DATA_ID, label: ROUTE.ENDPOINTS_DATA_LABEL }
        const endpointsData = processGeojson(endpointsGeojson)
        const endpointsDataset = { info: endpointsDataInfo, data: endpointsData }        

        // Options & Configs
        const options = { readOnly: true, centerMap: true, keepExistingConfig: true }

        // Dispatch `addDataToMap`
        dispatch( addDataToMap({ datasets: [ endpointsDataset, tripDataset ], options }) )

        // Apply Layer Configs
        const map = getState().keplerGl.map
        if (map) {
            const { layers } = map.visState
            layers.forEach(l => {
                // Route Layer
                if(l.config.dataId === tripDataInfo.id && l.type === 'geojson') {
                    dispatch( layerConfigChange(l, { label: 'Route' }) )
                    dispatch( layerVisConfigChange(l, { strokeColor: [ 18, 147, 154 ], thickness: 2 }) )

                } else if(l.config.dataId === ROUTE.ENDPOINTS_DATA_ID) {
                    // Endpoints Layer

                    const _endpointsDataset = getState().keplerGl.map.visState.datasets[ endpointsDataInfo.id ]

                    // Color By Point Type. i.e start / end
                    const colorRange = {
                        category: 'Barikoi',
                        colors: [ '#c90000', '#00FF00' ],
                        name: 'Route Endpoints',
                        type: 'quantile'
                    }

                    dispatch( layerVisConfigChange(l, { colorRange }) )

                    // Set Color Field by to distinguish Endpoint types
                    const typeField = _endpointsDataset.fields.find(el => el.name === 'endpoint_type')
        
                    // Dispatch Color Field By
                    dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )

                    // Tooltip Configs
                    const fieldsToShow = [
                        {
                            name: 'trip_id',
                            format: null
                        },
                        {
                            name: 'endpoint_type',
                            format: null
                        },
                        {
                            name: 'longitude',
                            format: null
                        },
                        {
                            name: 'latitude',
                            format: null
                        },
                        {
                            name: 'lat_long',
                            format: null
                        }
                    ]
                    const { tooltip } = map.visState.interactionConfig
                    tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
                    dispatch( interactionConfigChange(tooltip) )
                }
            })

            // Set Layer Size with Zoom Level
            const zoom = getState().keplerGl.map.mapState.zoom
            dispatch( setLayerSizeWithZoomLevel(zoom) )

            // Set Tooltip Propperties
            const routeDataset = getState().keplerGl.map.visState.datasets[ ROUTE.DATA_ID ]
            const fields = routeDataset ? routeDataset.fields : []
            const fieldsToShow = fields.filter(a => a.type !== 'geojson').map(f => ({ name: f.name, format: null }))

            if(fieldsToShow.length > 0) {
                const { tooltip } = getState().keplerGl.map.visState.interactionConfig
                tooltip.config.fieldsToShow[ ROUTE.DATA_ID ] = fieldsToShow
                dispatch( interactionConfigChange(tooltip) )
            }

            // Add Default Filters
            dispatch( addFilter(tripDataInfo.id) )
            dispatch( addFilter(endpointsDataInfo.id) )
        }
    }
}

// Trace a User
export function traceUser(selectedUserId) {
    return (dispatch, getState) => {
        // Get All users and filter out selectedUser
        getAllUsers()
            .then(users => {
                // console.log(JSON.stringify(users))
                // Remove previous Trace Data From Map
                dispatch( removeDataset(TRACKER.DATA_ID) )

                // Add User to Map
                const selectedUser = users.find(u => u.user_id === selectedUserId)

                if(selectedUser) {
                    const transformedData = transformApiData(selectedUser)
                    dispatch( updateTraceDataToMap(transformedData) )

                    // ////////////////////////////
                    // // Set Layer Configs Once //
                    // ////////////////////////////
                    // const { layers } = getState().keplerGl.map.visState
                    // layers.forEach(l => {
                    //     if(l.config.dataId === TRACKER.DATA_ID) {
                    //         const colorRange = {
                    //             category: 'Barikoi',
                    //             colors: [ '#c90000',  '#1fbad6',  ],
                    //             name: 'Trace Online/Offline',
                    //             type: 'quantile'
                    //         }
        
                    //         dispatch( layerVisConfigChange(l, { colorRange }) )

                    //         // Set Color Field by to distinguish online/offline
                    //         const trackerDataset = getState().keplerGl.map.visState.datasets[ TRACKER.DATA_ID ]
                    //         const activeStatusField = trackerDataset.fields.find(el => el.name === 'active_status')

                    //         // Dispatch Color Field By
                    //         dispatch( layerVisualChannelConfigChange(l, { colorField: activeStatusField }, 'color') )
                    //     }
                    // })
                }
            })
            .then(() => {
                // Activate Trace User Socket Connection
                dispatch( activateTraceUserSocket(selectedUserId) )

                // Check Online Status
                setInterval(function checkOnline() {
                    dispatch(checkOnlineStatus())
                    return checkOnline
                }(), 300000)
            })
            .catch(err => {
                console.error(err)
            })
    }
}

// Trace Group users
export function traceGroup(group) {
    return (dispatch, getState) => {
        // Get All users in the selected group
        getGroupUsers(group.id)
            .then(users => {
                // Remove previous Trace Data From Map
                dispatch( removeDataset(TRACKER.DATA_ID) )

                // Add Users to Map
                if(users.length > 0) {
                    const transformedData = transformApiData(users)
                    dispatch( updateTraceDataToMap(transformedData) )

                    // ////////////////////////////
                    // // Set Layer Configs Once //
                    // ////////////////////////////
                    // const { layers } = getState().keplerGl.map.visState
                    // layers.forEach(l => {
                    //     if(l.config.dataId === TRACKER.DATA_ID) {
                    //         const colorRange = {
                    //             category: 'Barikoi',
                    //             colors: [ '#c90000', '#1fbad6' ],
                    //             name: 'Trace Online/Offline',
                    //             type: 'quantile'
                    //         }
        
                    //         dispatch( layerVisConfigChange(l, { colorRange }) )

                    //         // Set Color Field by to distinguish online/offline
                    //         const trackerDataset = getState().keplerGl.map.visState.datasets[ TRACKER.DATA_ID ]
                    //         const activeStatusField = trackerDataset.fields.find(el => el.name === 'active_status')

                    //         // Dispatch Color Field By
                    //         dispatch( layerVisualChannelConfigChange(l, { colorField: activeStatusField }, 'color') )
                    //     }
                    // })
                }
            })
            .then(() => {
                // Activate Trace Group Socket Connection
                dispatch( activateTraceGroupSocket(group.group_name) )

                // Check Online Status
                setInterval(function checkOnline() {
                    dispatch(checkOnlineStatus())
                    return checkOnline
                }(), 300000)
            })
            .catch(err => {
                console.error(err)
            })
    }
}

// Trace All Users
export function traceAllUsers() {
    return (dispatch, getState) => {
        // Get All users
        getAllUsers()
            .then(users => {
                // Remove previous Trace Data From Map
                dispatch( removeDataset(TRACKER.DATA_ID) )

                // Add Users to Map
                if(users.length > 0) {
                    const transformedData = transformApiData(users)

                    // console.log('users', users)

                    // console.log('transformedData', transformedData)
                    dispatch( updateTraceDataToMap(transformedData) )

                    // ////////////////////////////
                    // // Set Layer Configs Once //
                    // ////////////////////////////
                    // const { layers } = getState().keplerGl.map.visState
                    // layers.forEach(l => {
                    //     if(l.config.dataId === TRACKER.DATA_ID) {
                    //         const colorRange = {
                    //             category: 'Barikoi',
                    //             colors: [ '#c90000', '#1fbad6' ],
                    //             name: 'Trace Online/Offline',
                    //             type: 'quantile'
                    //         }
        
                    //         dispatch( layerVisConfigChange(l, { colorRange }) )

                    //         // Set Color Field by to distinguish online/offline
                    //         const trackerDataset = getState().keplerGl.map.visState.datasets[ TRACKER.DATA_ID ]
                    //         const activeStatusField = trackerDataset.fields.find(el => el.name === 'active_status')

                    //         // Dispatch Color Field By
                    //         dispatch( layerVisualChannelConfigChange(l, { colorField: activeStatusField }, 'color') )
                    //     }
                    // })
                }
            })
            .then(() => {
                // Activate Trace All Users Socket Connection
                dispatch( activateTraceAllUsersSocket() )

                // Check Online Status
                setInterval(function checkOnline() {
                    dispatch(checkOnlineStatus())
                    return checkOnline
                }(), 300000)
            })
            .catch(err => {
                console.error(err)
            })
    }
}

// Activate Trace User Socket
function activateTraceUserSocket(selectedUserId) {
    return dispatch => {
        // Get Auth Token
        const token = localStorage.getItem('token')

        // Get Channel and Event from API
        axios.get(COMPANY_DASHBOARD.TRACE_USER_EVENT_API, { headers: { Authorization: 'Bearer ' + token } })
            .then(res => {
                const channel = res.data.data.channel_name
                const event = res.data.data.event_name

                window.traceUserPusher = new Pusher(TRACKER.KEY, {
                    wsHost: TRACKER.HOST,
                    wsPort: TRACKER.PORT,
                    wssPort: TRACKER.PORT,
                    wsPath: '',
                    disableStats: true,
                    authEndpoint: TRACKER.AUTH_ENDPOINT,
                    cluster: TRACKER.CLUSTER,
                    auth: {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    },
                    enabledTransports: [ 'ws', 'wss' ]
                })

                window.traceUserPusher.subscribe('private-' + channel).bind(event + '_' + selectedUserId, newData => {
                    // Transform Trace User Socket Data
                    const transformedNewData = transformTraceUserSocketData(newData)

                    dispatch( updateTraceDataToMap(transformedNewData) )
                })

                // Add Channel to Global scope to unsubscribe
                window.traceUserPusherChannel = 'private-' + channel
            })
            .catch(err => console.error(err))
    }
}

// Activate Trace Group Socket
function activateTraceGroupSocket(selectedGroupName) {
    return dispatch => {
        // Get Auth Token
        const token = localStorage.getItem('token')

        // Get Channel and Event from API
        axios.get(COMPANY_DASHBOARD.TRACE_GROUP_EVENT_API, { headers: { Authorization: 'Bearer ' + token } })
            .then(res => {
                const channel = res.data.data.channel_name
                const event = res.data.data.event_name + '_' + selectedGroupName.trim().replace(/\s+/g, '_').toLowerCase()

                window.traceGroupPusher = new Pusher(TRACKER.KEY, {
                    wsHost: TRACKER.HOST,
                    wsPort: TRACKER.PORT,
                    wssPort: TRACKER.PORT,
                    wsPath: '',
                    disableStats: true,
                    authEndpoint: TRACKER.AUTH_ENDPOINT,
                    cluster: TRACKER.CLUSTER,
                    auth: {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    },
                    enabledTransports: [ 'ws', 'wss' ]
                })

                window.traceGroupPusher.subscribe('private-' + channel).bind(event, newData => {
                    // Transform Trace User Socket Data
                    const transformedNewData = transformTraceUserSocketData(newData)

                    // console.log('activateTraceGroupSocket', newData)
                    
                    dispatch( updateTraceDataToMap(transformedNewData) )
                })

                // Add Channel to Global scope to unsubscribe
                window.traceGroupPusherChannel = 'private-' + channel
            })
            .catch(err => console.error(err))
    }
}

// Activate Trace All Users Socket
function activateTraceAllUsersSocket() {
    return dispatch => {
        // Get Auth Token
        const token = localStorage.getItem('token')

        // Get Channel and Event from API
        axios.get(COMPANY_DASHBOARD.TRACE_ALL_USERS_EVENT_API, { headers: { Authorization: 'Bearer ' + token } })
            .then(res => {
                const channel = res.data.data.channel_name
                const event = res.data.data.event_name

                window.traceAllUsersPusher = new Pusher(TRACKER.KEY, {
                    wsHost: TRACKER.HOST,
                    wsPort: TRACKER.PORT,
                    wssPort: TRACKER.PORT,
                    wsPath: '',
                    disableStats: true,
                    authEndpoint: TRACKER.AUTH_ENDPOINT,
                    cluster: TRACKER.CLUSTER,
                    auth: {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    },
                    enabledTransports: [ 'ws', 'wss' ]
                })

                window.traceAllUsersPusher.subscribe('private-' + channel).bind(event, newData => {
                    // Transform Trace User Socket Data
                    const transformedNewData = transformTraceUserSocketData(newData)

                    // console.log('activateTraceAllUsersSocket', newData)

                    dispatch( updateTraceDataToMap(transformedNewData) )
                })

                // Add Channel to Global scope to unsubscribe
                window.traceAllUsersPusherChannel = 'private-' + channel
            })
            .catch(err => console.error(err))
    }
}

// Deactivate Trace User Socket
export function deactivateTraceUserSocket() {
    return () => {
        if(window.traceUserPusher && window.traceUserPusherChannel) {
            window.traceUserPusher.unsubscribe(window.traceUserPusherChannel)
            delete window.traceUserPusher
            delete window.traceUserPusherChannel
        }
    }
}

// Deactivate Trace Group Socket
export function deactivateTraceGroupSocket() {
    return () => {
        if(window.traceGroupPusher && window.traceGroupPusherChannel) {
            window.traceGroupPusher.unsubscribe(window.traceGroupPusherChannel)
            delete window.traceGroupPusher
            delete window.traceGroupPusherChannel
        }
    }
}

// Deactivate Trace All Users Socket
export function deactivateTraceAllUsersSocket() {
    return () => {
        if(window.traceAllUsersPusher && window.traceAllUsersPusherChannel) {
            window.traceAllUsersPusher.unsubscribe(window.traceAllUsersPusherChannel)
            delete window.traceAllUsersPusher
            delete window.traceAllUsersPusherChannel
        }
    }
}

// Check Online Status
function checkOnlineStatus() {
    return (dispatch, getState) => {
        // Get Current Datasets and Config
        // Get Existing Data and update active_status
        const dataInfo = { id: TRACKER.DATA_ID, label: TRACKER.DATA_LABEL }
        const options = { readOnly: true, keepExistingConfig: true }

        const { map } = getState().keplerGl
        if(map) {
            const { zoom, latitude, longitude } = map.mapState
            const trackerDataset = map.visState.datasets[ TRACKER.DATA_ID ]

            if(trackerDataset) {
                // Get active_status Index
                const activeIndex = trackerDataset.fields.findIndex(el => el.name === 'active_status')
                const updatedAtIndex = trackerDataset.fields.findIndex(el => el.name === 'updated_at')

                // Check if same data Id exists in the dataset as active users
                if (trackerDataset.dataContainer) {
                    if (trackerDataset.dataContainer._rows) {
                        const dataLength = trackerDataset.dataContainer._rows.length
                        for(let i = 0; i < dataLength; i++) {
                            // Check `updated_at`. If `online` and 5 minutes passed after `updated_at` then set `offline`
                            const updatedAt = trackerDataset.dataContainer._rows[i][updatedAtIndex]
                            const updatedAtDate = new Date(updatedAt)
                            const updatedAtTime = updatedAtDate.getTime()
                            const currentTime = Date.now()
                            const elapsed = currentTime - updatedAtTime
                            const activeStatus = trackerDataset.dataContainer._rows[i][activeIndex]
                            const offlineThresholdDelay = 600000

                            if(activeStatus === 'online' && elapsed >= offlineThresholdDelay) {
                                trackerDataset.dataContainer._rows[i][activeIndex] = 'offline'
                            }
                        }

                        const data = {
                            fields: trackerDataset.fields,
                            rows: trackerDataset.dataContainer._rows
                        }

                        const dataset = { info: dataInfo, data }

                        // Dispatch `addDataToMap`
                        dispatch( addDataToMap({ datasets: dataset, options }) )

                        // Update Map State to Current State
                        dispatch(updateMap({ zoom, latitude, longitude }))

                        // Apply Layer Configs
                        const { layers } = getState().keplerGl.map.visState
                        layers.forEach(l => {
                            if (l.config.dataId === dataInfo.id) {
                                // Set Color Range
                                let colors = ['#c90000', '#1fbad6']
                                //////////////////////////////
                                /////// TBC /////////////////
                                // const uniqStatus = _uniqBy(trackerDataset.dataContainer._rows, 'active_status')

                                const activeStatusIndex = trackerDataset.fields.findIndex(f => f.name === 'active_status')

                                if (activeStatusIndex >= 0) {
                                    const uniqStatus = uniqByIndex(trackerDataset.dataContainer._rows, activeStatusIndex)
                                
                                    if (uniqStatus.length === 1) {
                                        if (uniqStatus[0][activeStatusIndex]) {
                                            if (uniqStatus[0][activeStatusIndex] === 'offline') {
                                                colors = ['#c90000']
                                                
                                            } else if (uniqStatus[0][activeStatusIndex] === 'online') {
                                                colors = [ '#1fbad6' ]
                                            }
                                        }
                                    }
                                    
                                    const colorRange = {
                                        category: 'Barikoi',
                                        colors,
                                        name: 'Trace Online/Offline',
                                        type: 'quantile'
                                    }
                
                                    dispatch(layerVisConfigChange(l, { colorRange }))   
                                }
                                
                            }
                        })
                    }  
                }
            }
        }
    }
}

// Update Trace Data to Map
function updateTraceDataToMap(newData) {
    return (dispatch, getState) => {
        const { map } = getState().keplerGl
        if (map) {
            // Get Previous Dataset
            const prevDataset = map.visState.datasets[ TRACKER.DATA_ID ]

            if (prevDataset) {
                // Process New Data
                const processedNewData = processRowObject(newData)

                const comparatorColumnIndex = prevDataset.fields.findIndex(f => f.name === 'user_id')
                
                if (comparatorColumnIndex >= 0) {
                    // Build DataSet
                    const dataInfo = { id: prevDataset.id, label: prevDataset.label }
                    const data = {
                        fields: processedNewData.fields,
                        rows: unionByIndex(prevDataset.dataContainer._rows, processedNewData.rows, comparatorColumnIndex)
                    }
                    const dataset = { info: dataInfo, data }

                    // Options & Configs
                    const options = { readOnly: true, keepExistingConfig: true }

                    // Add Data To Map
                    dispatch(addDataToMap({
                        datasets: dataset,
                        options
                    }))

                    // Update Map State
                    dispatch(updateMap(map.mapState))
                    
                    // Set Layer Radius
                    dispatch( setLayerSizeWithZoomLevel(map.mapState.zoom) )

                    // Apply Layer Configs
                    const { layers } = getState().keplerGl.map.visState
                    layers.forEach(l => {
                        if (l.config.dataId === dataInfo.id) {
                            // Set Color Range
                            let newColors = [ '#c90000',  '#1fbad6' ]
                            const activeStatusIndex = prevDataset.fields.findIndex(f => f.name === 'active_status')

                                if (activeStatusIndex >= 0) {
                                    const uniqStatus = uniqByIndex(prevDataset.dataContainer._rows, activeStatusIndex)
                                
                                    if (uniqStatus.length === 1) {
                                        if (uniqStatus[0][activeStatusIndex]) {
                                            if (uniqStatus[0][activeStatusIndex] === 'offline') {
                                                newColors = ['#c90000']
                                                
                                            } else if (uniqStatus[0][activeStatusIndex] === 'online') {
                                                newColors = [ '#1fbad6' ]
                                            }
                                        }
                                    }
                                    
                                    const colorRange = {
                                        category: 'Barikoi',
                                        colors: newColors,
                                        name: 'Trace Online/Offline',
                                        type: 'quantile'
                                    }
                
                                    dispatch(layerVisConfigChange(l, { colorRange }))   
                                }
                        }
                    })
                }
                
            } else {
                // Remove Previous Dataset
                dispatch(removeDataset(TRACKER.DATA_ID))
                
                // Build DataSet
                const dataInfo = { id: TRACKER.DATA_ID, label: TRACKER.DATA_LABEL }
                const data = processRowObject(newData)
                const dataset = { info: dataInfo, data }

                // Options & Configs
                const options = { readOnly: true }

                // Add Data To Map
                dispatch(addDataToMap({ datasets: dataset, options }))


                // Apply Layer Configs
                const { layers } = getState().keplerGl.map.visState
                layers.forEach((l, i) => {
                    if (l.config.dataId === dataInfo.id) {
                        if (l.type === 'point') {
                            // Remove Point Layer
                            dispatch( removeLayer(i) )

                        } else {
                            // Set Layer Label
                            dispatch( layerConfigChange(l, { label: 'Trace' }) )
                            
                            // Set Tracker Text Label to `name`
                            const traceDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
                            const nameField = traceDataset.fields.find(el => el.name === 'name')
                            if (nameField) {
                                dispatch(layerTextLabelChange(l, 0, 'field', nameField))
                                dispatch( layerTextLabelChange(l, 0, 'color', [0, 0, 0]) )
                            }

                            // Set Tooltip Propperties
                            const fieldsToShow = [
                                {
                                    name: 'name',
                                    format: null
                                },
                                {
                                    name: 'updated_at',
                                    format: null
                                }
                            ]
                            const { tooltip } = getState().keplerGl.map.visState.interactionConfig
                            tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
                            dispatch(interactionConfigChange(tooltip))
                            
                            // Set Color Range
                            let newColors = [ '#c90000',  '#1fbad6' ]
                            const uniqStatus = _uniqBy(newData, 'active_status')
                            if (uniqStatus.length === 1) {
                                if (uniqStatus[0].active_status) {
                                    if (uniqStatus[0].active_status === 'offline') {
                                        newColors = ['#c90000']
                                        
                                    } else if (uniqStatus[0].active_status === 'online') {
                                        newColors = [ '#1fbad6' ]
                                    }
                                }
                            }

                            const colorRange = {
                                category: 'Barikoi',
                                colors: newColors,
                                name: 'Trace Online/Offline',
                                type: 'quantile'
                            }
        
                            dispatch(layerVisConfigChange(l, { colorRange }))
                            
                            // Set Color Field by to distinguish online/offline
                            const activeStatusField = traceDataset.fields.find(el => el.name === 'active_status')

                            // Dispatch Color Field By
                            dispatch(layerVisualChannelConfigChange(l, { colorField: activeStatusField }, 'color'))

                            // Set Layer Radius
                            const { zoom } = getState().keplerGl.map.mapState
                            dispatch( setLayerSizeWithZoomLevel(zoom) )
                        }
                    }
                })
            }
        }
    }
}

function unionByIndex(arr1, arr2, columnIndex) {
    const temp = arr1

    arr2.forEach(r => {
        // Search in temp
        const tempRowIndex = temp.findIndex(t => t[columnIndex] === r[columnIndex])
        
        if (tempRowIndex >= 0) {
            temp[tempRowIndex] = r
            
        } else {
            temp.push(r)
        }
    })

    // console.log({temp})
    return temp
}

// Transform Trace API Data
function transformApiData(data) {

    // console.log('transformApi__Data', data)
    let transformedData = []
    if(Array.isArray(data)) {
        transformedData = data.map(i => ({
            user_id: i.user_id,
            name: i.name,
            latitude: i.user_last_lat,
            longitude: i.user_last_lon,
            updated_at: i.position_updated_at ? i.position_updated_at : i.updated_at,
            active_status: new Date() - new Date(i.position_updated_at ? i.position_updated_at : i.updated_at) < 300000 ? 'online' : 'offline',
            icon: 'profile'
        }))

    } else {
        transformedData = [{
            user_id: data.user_id,
            name: data.name,
            latitude: data.user_last_lat,
            longitude: data.user_last_lon,
            updated_at: data.position_updated_at ? data.position_updated_at : data.updated_at,
            active_status: new Date() - new Date(data.position_updated_at ? data.position_updated_at : data.updated_at) < 300000 ? 'online' : 'offline',
            icon: 'profile'
        }]
    }

    return transformedData
}

// Transform Trace User New Socket Data
function transformTraceUserSocketData(newData) {
    let data = newData
    if(newData.position && typeof newData.position === 'string') {
        data = JSON.parse(newData.position)
    }

    const socketData = [{
        user_id: data.user_id ? data.user_id : data.data.user_id,
        name: data.name ? data.name : data.data.name,
        latitude: Number(data.latitude ? data.latitude : data.data.latitude),
        longitude: Number(data.longitude ? data.longitude : data.data.longitude),
        updated_at: data.updated_at ? data.updated_at : data.data.updated_at,
        active_status: (data.active_status === 1 || data.data.active_status === 1) ? 'online' : 'offline',
        icon: 'profile'
    }]

    // console.log('soc', socketData)

    return socketData
}

// Filter User Trips
export function filterUserTrips(tripInfo) {
    return (dispatch, getState) => {
        // Get Trip and Endpoints Filter Index
        const tripFilterIndex = getState().keplerGl.map.visState.filters.findIndex(f => f.dataId[0] === ROUTE.DATA_ID)
        const endpointsFilterIndex = getState().keplerGl.map.visState.filters.findIndex(f => f.dataId[0] === ROUTE.ENDPOINTS_DATA_ID)

        let value = [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]
        if(tripInfo) {
            value = [ tripInfo.trip_id, tripInfo.trip_id ]
        }
        
        if(tripFilterIndex >= 0 && endpointsFilterIndex >= 0) {
            dispatch( setFilter(tripFilterIndex, 'name', 'trip_id') )
            dispatch( setFilter(endpointsFilterIndex, 'name', 'trip_id') )

            dispatch( setFilter(tripFilterIndex, 'value', value) )
            dispatch( setFilter(endpointsFilterIndex, 'value', value) )

            // Update Map Center
            if (tripInfo.path.geo_json.coordinates && tripInfo.path.geo_json.coordinates.length > 0) {
                dispatch( updateMap({
                    longitude: tripInfo.path.geo_json.coordinates[0][0],
                    latitude: tripInfo.path.geo_json.coordinates[0][1]
                }))       
            }
        }
    }
}

// Get API_KEY from Token
export function getApiKeyFromToken() {
    // Get Auth Token
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_API_KEY_FROM_TOKEN_API, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data.data
        })
        .catch(err => {
            throw err.response.data
        })
}

// Get Single Trip Data
export function getSingleTripData(tripId) {
    // Get Auth Token
    const token = localStorage.getItem('token')

    return axios.get('https://backend.barikoi.com:8888/api/v1/trips/' + tripId + '/gpx-csv', { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get User Trip Timeline Within Time Range
export function getUserTripTimelineWithinTimeRange(params) {
    // Get Auth Token
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_USER_TRIP_TIMELINE_WITHIN_TIME_RANGE,
        { params, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data.data
        })
        .catch(err => {
            throw err
        })
}

// Get User Route Points Within Time Range
export function getUserRoutePointsWithinTimeRange(params) {
    // Get Auth Token
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_USER_ROUTE_POINTS, { params, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {            
            return res.data.data
        })
        .catch(err => {
            throw err
        })
}

// Get User Route Points Within Time Range
export function getUserRoutePointsWithinTimeRangeImproved(params) {
    // Get Auth Token
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_USER_ROUTE_POINTS_IMPROVED, { params, headers: { Authorization: 'Bearer ' + token } })
        .then(res => {            
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Dispatch User Trip Timeline Data to Map
export function dispatchUserTripTimelineDataToMap(geojsonList, flag = null) {
    return (dispatch, getState) => {
        
        // Extrace LineString And StallPoints Data
        const tripGeojson = {
            type: 'FeatureCollection',
            features: geojsonList.length > 0 ?
                geojsonList.map(g =>
                    g.features.filter(f => f.geometry.type === 'LineString')
                ).flat()
                :
                []
        }

        const endpointsGeojson = {
            type: 'FeatureCollection',
            features: geojsonList.length > 0 ?
                geojsonList.map(g =>
                    g.features.filter(f => f.geometry.type === 'Point')
                ).flat()
                :
                []
        }

        // Clear Previous Route & Endpoints Dataset
        dispatch( removeDataset( flag ? flag.DATA_ID : ROUTE.DATA_ID) )
        dispatch( removeDataset( flag ? flag.ENDPOINTS_DATA_ID : ROUTE.ENDPOINTS_DATA_ID) )

        // Build Datasets
        const tripDataInfo = {
            id: flag ? flag.DATA_ID : ROUTE.DATA_ID,
            label: flag ? flag.DATA_LABEL : ROUTE.DATA_LABEL
        }
        const tripData = processGeojson(tripGeojson)
        const tripDataset = { info: tripDataInfo, data: tripData }

        const endpointsDataInfo = {
            id: flag ? flag.ENDPOINTS_DATA_ID : ROUTE.ENDPOINTS_DATA_ID,
            label: flag ? flag.ENDPOINTS_DATA_LABEL : ROUTE.ENDPOINTS_DATA_LABEL
        }
        const endpointsData = processGeojson(endpointsGeojson)
        const endpointsDataset = { info: endpointsDataInfo, data: endpointsData }

        // Options & Configs
        const options = { readOnly: true, centerMap: true, keepExistingConfig: true }

        const datasets = []
        
        if (endpointsDataset && endpointsDataset.data) {
            datasets.push(endpointsDataset)
        }
        if (tripDataset && tripDataset.data) {
            datasets.push(tripDataset)
        }
        // Dispatch `addDataToMap`
        dispatch( addDataToMap({ datasets, options }) )

        // Apply Layer Configs
        const { layers } = getState().keplerGl.map.visState        
        layers.forEach(l => {
            // Route Layer
            if(l.config.dataId === tripDataInfo.id && l.type === 'geojson') {
                dispatch( layerConfigChange(l, { label: 'Route' }) )
                dispatch( layerVisConfigChange(l, { strokeColor: [ 18, 147, 154 ], thickness: 2 }) )

            } else if (l.config.dataId === (flag ? flag.ENDPOINTS_DATA_ID : ROUTE.ENDPOINTS_DATA_ID)) {
                
                // Endpoints Layer
                const _endpointsDataset = getState().keplerGl.map.visState.datasets[endpointsDataInfo.id]
                
                // Check user route points avaibility
                // const isRoutePointsAvailable = _endpointsDataset.dataContainer._rows.some(el => el[8] === 'route_points')                

                // Color By Point Type. i.e start / end
                const colorRange = {
                    category: 'Barikoi',
                    colors: flag ? [ '#5C33F6', '#c90000' ] : [ '#c90000' ],
                    name: 'Route Endpoints',
                    type: 'quantile'
                }                

                dispatch( layerVisConfigChange(l, { colorRange }) )

                // Set Color Field by to distinguish Endpoint types
                const typeField = _endpointsDataset.fields.find(el => el.name === 'endpoint_type')
                
                // Dispatch Color Field By
                dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
            }
        })

        // Set Layer Size with Zoom Level
        const zoom = getState().keplerGl.map.mapState.zoom
        dispatch( setLayerSizeWithZoomLevel(zoom) )

        // Set Tooltip Propperties
        const validFields = [ 'address', 'endpoint_type', 'start_time', 'end_time', 'distance', 'duration', 'shop_name' ]
        const routeDataset = getState().keplerGl.map.visState.datasets[ ( flag ? flag.ENDPOINTS_DATA_ID : ROUTE.ENDPOINTS_DATA_ID) ]
        const fields = routeDataset ? routeDataset.fields : []
        const fieldsToShow = fields.filter(a => validFields.includes(a.name)).map(f => ({ name: f.name, format: null }))

        if(fieldsToShow.length > 0) {
            const { tooltip } = getState().keplerGl.map.visState.interactionConfig
            tooltip.config.fieldsToShow[ (flag ? flag.ENDPOINTS_DATA_ID : ROUTE.ENDPOINTS_DATA_ID) ] = fieldsToShow
            dispatch( interactionConfigChange(tooltip) )
        }

        // Add Default Filters
        dispatch( addFilter(tripDataInfo.id) )
        dispatch( addFilter(endpointsDataInfo.id) )
    }
}

// Ger Serviq Analytics
export function getServiqAnalytics() {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_SERVIQ_ANALYTICS, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Get Group Analytics within time Range for Serviq
export function getServiqAnalyticsWithinTimeRange(params) {
    const token = localStorage.getItem('token')

    return axios.get(COMPANY_DASHBOARD.GET_SERVIQ_ANALYTICS_WITHIN_TIME_RANGE, { params, headers: { Authorization: 'Bearer ' + token }})
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Update Route Point
export const updateRoutePoint = (routeInfo, id) => {
    const token = localStorage.getItem('token')

    return axios.put(`${COMPANY_DASHBOARD.UPDATE_ROUTE_POINT}/${id}`, routeInfo, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Check Uniq By Index
function uniqByIndex(arr, index) {
    let unq = []
    arr.forEach(a => {
      if(index >= 0 && index < a.length) {
        if(unq.length > 0) {
          if(!unq.find(u =>  u[index] === a[index])) {
            unq.push(a)
          }
        
        } else {
          unq.push(a)
        }  
      }
    })
    
    return unq
}

// Get User List By Group ID
export const getUsersByGroupId = (params) => {
    const token = localStorage.getItem('token')
    if (params) {
        return axios.get(COMPANY_DASHBOARD.GET_USER_LIST, { params, headers: { Authorization: 'Bearer ' + token }})
            .then(res => {
                return res.data
            })
            .catch(err => {
                throw err
            })
    }
}

// Get Area List By User ID
export const getAreaByUserId = (params) => {
    const token = localStorage.getItem('token')
    if (params) {
        return axios.get(COMPANY_DASHBOARD.GET_AREA_LIST, { params, headers: { Authorization: 'Bearer ' + token }})
            .then(res => {
                return res.data
            })
            .catch(err => {
                throw err
            })
    }
}

// Get User Analytics
export const getUserAnalytics = (params) => {
    const token = localStorage.getItem('token')
    if (params) {
        return axios.get(COMPANY_DASHBOARD.GET_USER_ANALYTICS, { params, headers: { Authorization: 'Bearer ' + token }})
            .then(res => {
                return res.data
            })
            .catch(err => {
                throw err
            })
    }
}

// Activate Geofence Socket
export const activateGeofenceSocket = (params) => {    
        // Initially Deactivae Geofence Socket
        deactivateGeofenceSocket()

        // Get Auth Token
        const token = localStorage.getItem('token')
        const localUserId = localStorage.getItem('userId')
        
        const channel = `private-route-point-geofence-${localUserId}`
        const event = 'get-points'    

        window.geofencePusher = new Pusher(TRACKER.KEY, {
            wsHost: TRACKER.HOST,
            wsPort: TRACKER.PORT,
            wssPort: TRACKER.PORT,
            wsPath: '',
            disableStats: true,
            authEndpoint: TRACKER.AUTH_ENDPOINT,
            cluster: TRACKER.CLUSTER,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            },
            enabledTransports: [ 'ws', 'wss' ]
        })

        // console.log('winGeo', window.geofencePusher)
        const geofenceData = []

        return new Promise((resolve, reject) => {
            // Get Channel and Event from API
            axios.get(COMPANY_DASHBOARD.GET_USER_ROUTE_POINTS_IMPROVED, { params, headers: { Authorization: 'Bearer ' + token } })
                .then(res => {
                    // console.log('res', res)
                    if (res.data.status === 202) {
                        // console.log('status 202')
                        window.geofencePusher.subscribe(channel).bind(event, newData => {
                            // console.log('geofence data', newData)
                            if (isArray(newData)) {
                                if (newData[0] === 'ENDED') {
                                    deactivateGeofenceSocket()                            
                                    resolve( geofenceData )
                                }                                           
                            } else {
                                geofenceData.push(newData)
                            }
                        })                
        
                        // Add Channel to Global scope to unsubscribe
                        window.geofencePusherChannel = channel    
                    } else {
                        deactivateGeofenceSocket()
                        reject('Something went wrong.')
                    }
                })
                .catch(err => {                
                    deactivateGeofenceSocket()
                    reject(err)
                })
        })
}

// Deactivate Geofence Socket
export function deactivateGeofenceSocket() {
    // console.log('deactivated func started')
    if (window.geofencePusher && window.geofencePusherChannel) {
        // console.log('deactivation started')
        window.geofencePusher.unsubscribe(window.geofencePusherChannel)
        delete window.geofencePusher
        delete window.geofencePusherChannel
        // console.log('deactivation ended')
    }    
}

// Get User Specific Total Route Point Count
export const getUserRoutePointCount = (data) => {
    const token = localStorage.getItem('token')

    // If Params is empty
    if(!data) {
        throw new Error('Params is empty')
    }
    
    const params = {
        ...data,
        timeFrom: `${format(data.timeFrom, 'yyyy-MM-dd')} 00:00:00`,
        timeTo: `${format(data.timeTo, 'yyyy-MM-dd')} 23:59:59`,
    }    

    return axios.get(COMPANY_DASHBOARD.GET_USER_ROUTE_POINT_COUNT, { params, headers: { Authorization: 'Bearer ' + token }})
        .then(res => {            
            return res.data
        })
        .catch(err => {
            throw err
        })
}