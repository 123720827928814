import React from 'react'
import { format } from 'date-fns'

// Import Components
import { Box, Grid, Paper, InputLabel } from '@material-ui/core'
import StyledLinearProgress from '../../common/StyledLinearProgress'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledDateTimePicker from '../../common/StyledDateTimePicker'
import StyledDataGrid2 from '../../common/StyledDataGrid2'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'

// Import Actions
import { getUserRoutePointCount, getAllGroups } from '../../../store/actions/companyDashboardActions'

class UserRoutePointsCount extends React.PureComponent {
    state = {
        loading: false,
        startTime: new Date(),
        endTime: new Date(),
        userCountList: [],        
        isSnackbarOpen: false,
        isSuccessful: false,
        feedbackMessage: '',
        total: {},
        groupList: [],
        selectedGroup: -1,
    }

    componentDidMount = () => {
        
        // Get Group List
        getAllGroups()
            .then(groups => {
                const groupList = groups.map(g => ({ ...g, value: g.id, label: g.group_name }))

                this.setState({ groupList })

            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching groups.', loading: false })
            })
    }

    // On Start Date-Time Input Change
    _onStartDateTimeChange = startTime => {
        this.setState({ startTime })
    }

    // On End Date-Time Input Change
    _onEndDateTimeChange = endTime => {
        this.setState({ endTime })
    }

    // On Form Submit
    _onSubmit = e => {
        if (e) {
            e.preventDefault()
        }

        const { startTime, endTime, selectedGroup } = this.state
        
        // Validate Data
        if(!startTime || !endTime) {
            this.setState({
                isSnackbarOpen: true,
                isSuccessful: false,
                feedbackMessage: 'Select valid inputs.'
            })

            return
        }

        if(endTime - startTime < 0) {
            this.setState({
                isSnackbarOpen: true,
                isGetUserTripsSuccessful: false,
                feedbackMessage: 'End Time must be later than Start Time.'
            })

            return
        }

        // Set Loading
        this.setState({ loading: true, userCountList: [] })

        // Get User Route Points Count Within Time Range
        getUserRoutePointCount({ timeFrom: startTime, timeTo: endTime, company_group_id: selectedGroup })
            .then(res => {
                const result = res?.data?.length > 0 ? res.data.map((r, i) => (
                    { ...r,
                        id: i + 1,
                        startTime: `${format(startTime, 'yyyy-MM-dd')}`, 
                        endTime: `${format(endTime, 'yyyy-MM-dd')}` 
                    })) : []
                    
                this.setState({ userCountList: result, total: res?.total ?? {}, loading: false })               
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, isSuccessful: false, feedbackMessage: err.message ? err.message : err, loading: false })
            })                
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // Handle On Change
    _onChange = e => {        
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const { loading, startTime, endTime, userCountList, isSuccessful, isSnackbarOpen, feedbackMessage, total, groupList, selectedGroup } = this.state
        
        return (
            <Box style={ containerStyles }>
                {
                    loading &&
                    <StyledLinearProgress />
                }

                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 } >
                        <Paper style={ paperStyles } elevation={ 4 }>
                        <Box width='100%'>
                                <Paper variant='outlined' style={ topPaperStyles }>
                                    <form onSubmit={ this._onSubmit } style={ formStyles }>
                                        <Grid container={ true } spacing={ 2 } style={{ padding: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid item={ true } xs={ 12 } sm={ 3 }>
                                                <InputLabel required={ true } style={{ fontSize: '14px' }}>{ 'Select Group' }</InputLabel>
                                                <StyledSelectMenuVersion2
                                                    menuItems={ groupList }
                                                    label='Select Group'
                                                    name='selectedGroup'
                                                    required={ true }
                                                    value={ selectedGroup }
                                                    onChange={ this._onChange }
                                                />
                                            </Grid>
                                            <Grid item={ true } xs={ 12 } sm={ 3 }>
                                                <InputLabel required={ true } style={{ fontSize: '14px' }}>{ 'Start Date' }</InputLabel>
                                                <StyledDateTimePicker
                                                    value={ startTime }
                                                    onChange={ this._onStartDateTimeChange }
                                                    inputProps={{ required: true, style: { height: '36px' } }}
                                                />
                                            </Grid>

                                            <Grid item={ true } xs={ 12 } sm={ 3 }>
                                                <InputLabel required={ true } style={{ fontSize: '14px' }}>{ 'End Date' }</InputLabel>
                                                <StyledDateTimePicker
                                                    value={ endTime }
                                                    onChange={ this._onEndDateTimeChange }
                                                    inputProps={{ required: true, style: { height: '36px' } }}
                                                />
                                            </Grid>

                                            <Grid item={ true } xs={ 12 } sm={ 3 } style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                <StyledSubmitButton
                                                    type='submit'
                                                    fullWidth={ true }
                                                    variant='contained'
                                                    style={{ marginBottom: '-12px', }}
                                                >
                                                    { 'Get Counts' }
                                                </StyledSubmitButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Paper>
                            </Box>

                            <Box width='100%' height='100%' display='flex' flexDirection='row' justifyContent='center' alignItems='stretch'>                               
                                    <StyledDataGrid2
                                        title={ `User Route Points Count (Table)` }
                                        columns={[
                                            { field: 'name', headerName: 'Name', flex: 1 },
                                            { field: 'total', headerName: 'Total ( all time )', flex: 1 },
                                            { field: 'current', headerName: 'Total ( within date range )', flex: 1 },
                                            { field: 'startTime', headerName: 'Start Date', flex: 1 },
                                            { field: 'endTime', headerName: 'End Date', flex: 1 },
                                        ]}
                                        rows={ userCountList }
                                        toolbar={ true }                                        
                                        fileName={ 'user_route_point_counts' }
                                        check={ 'route_counts' }
                                        total={ total }
                                    />                                
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

const paperStyles = {
    width: '100%',
    height: '500px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch'
}

const topPaperStyles = {
    width: '100%',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const formStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

export default UserRoutePointsCount