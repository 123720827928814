// Base URL [Production]
const BASE_URL = 'https://backend.barikoi.com:8888/api/v1'

// // Base URL [Temp]
// const BASE_URL = 'http://trace.bkoih3.ml/api/v1'

// App Configurations
export const MAP_CONFIG = {
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN || null,
}

// Tracker Configurations
export const TRACKER = {
    KEY: 'mykey',
    HOST: 'backend.barikoi.com',
    PORT: 6002,
    AUTH_ENDPOINT: 'https://backend.barikoi.com:8888/api/broadcasting/auth',
    CLUSTER: 'ap2',
    DATA_ID: 'barikoi_tracker_data',
    DATA_LABEL: 'Barikoi Tracker Data'
}

// Authentication Configs
export const AUTH = {
    LOGIN_API: `${BASE_URL}/login`,
    TRACE_COMPANY_ADMIN_LOGIN_API: `${BASE_URL}/dashboard/login`,
    AUTH_USER: `${BASE_URL}/auth/user`,
    COMPANY_SIGNUP: `${BASE_URL}/company/register`,
    USER_CHANGE_PASSWORD: `${BASE_URL}/change-password`,
    FORGOT_PASSWORD: `${BASE_URL}/request-reset-password`,
    RESET_PASSWORD: `${BASE_URL}/reset-password`,
}

// Route Configs
export const ROUTE = {
    DATA_ID: 'route_data',
    DATA_LABEL: 'Route',
    ENDPOINTS_DATA_ID: 'endpoints_data',
    ENDPOINTS_DATA_LABEL: 'Endpoints',
    TRIPS_POINT_DATA_ID: 'trips_point_data',
    TRIPS_POINT_DATA_LABEL: 'TripPoints'
}

// Company Dashboard Configs
export const COMPANY_DASHBOARD = {
    GET_ANALYTICS: `${BASE_URL}/analytics`,
    GET_ANALYTICS_WITHIN_TIME_RANGE: `${BASE_URL}/analytics/time-wise`,
    ADD_USER_API: `${BASE_URL}/new/user`,
    ADD_BULK_USER_API: `${BASE_URL}/register/csv`,
    GET_ALL_USERS_API: `${BASE_URL}/users`,
    UPDATE_USER_API: `${BASE_URL}/user/update`,
    DELETE_USER_API: `${BASE_URL}/users/`,
    CREATE_GROUP_API: `${BASE_URL}/group/add`,
    GET_ALL_GROUPS_API: `${BASE_URL}/company/groups`,
    ASSIGN_USER_TO_GROUP_API: `${BASE_URL}/group/user/add`,
    GET_COMPANY_SETTINGS_API: `${BASE_URL}/company-settings`,
    UPDATE_COMPANY_SETTINGS_API: `${BASE_URL}/company-settings`,
    GET_USER_TRIPS_WITHIN_TIME_RANGE_API: `${BASE_URL}/business/user/trips/improved`,    
    TRACE_USER_EVENT_API: `${BASE_URL}/single/event`,
    GET_GROUP_USERS_API: `${BASE_URL}/company/group/users`,
    TRACE_GROUP_EVENT_API: `${BASE_URL}/group/event`,
    TRACE_ALL_USERS_EVENT_API: `${BASE_URL}/user/all/event`,
    GET_API_KEY_FROM_TOKEN_API: `${BASE_URL}/token`,
    GET_USER_TRIP_TIMELINE_WITHIN_TIME_RANGE: `${BASE_URL}/get/timeline`,
    GET_SERVIQ_ANALYTICS: `${BASE_URL}/user/company/info`,
    GET_SERVIQ_ANALYTICS_WITHIN_TIME_RANGE: `${BASE_URL}/group/analytics`,
    UPDATE_ROUTE_POINT: `${BASE_URL}/route-point`,
    GET_USER_ROUTE_POINTS: `${BASE_URL}/get/route-points/geofence`,
    GET_USER_ROUTE_POINTS_IMPROVED: `${BASE_URL}/get/route-points/geofence-improved`,
    GET_USER_LIST: `${BASE_URL}/company/group/users`,
    GET_USER_ANALYTICS: `${BASE_URL}/user/analytics`,
    GET_AREA_LIST: `${BASE_URL}/admin/route-point/areas`,
    GET_ACTIVE_TRIPS: `${BASE_URL}/business/user/trips/active/improved`,
    GET_ACTIVE_TRIPS_TEST: `${BASE_URL}/business/user/trips/active/improved/debug`,
    GET_USER_TRIPS_WITHIN_TIME_RANGE_API_TEST: `${BASE_URL}/business/user/trips/improved/debug`,
    GET_USER_ROUTE_POINT_COUNT: `${BASE_URL}/get-user-added-route-points`,
}