import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

// Import Styles
import './App.css'

// Import Components
import Box from '@material-ui/core/Box'
import TraceLogin from './pages/TraceLogin'
import CompanySignUp from './pages/CompanySignUp'
import CircularProgress from '@material-ui/core/CircularProgress'


import Menu from './components/AdminDashboard/Menu/Menu'
import Analytics from './components/AdminDashboard/MainBody/Analytics'
import AnalyticsForServiq from './components/AdminDashboard/MainBody/AnalyticsForServiq'
import AddUser from './components/AdminDashboard/MainBody/AddUser'
import UpdateUser from './components/AdminDashboard/MainBody/UpdateUser'
import CreateGroup from './components/AdminDashboard/MainBody/CreateGroup'
import AssignUserToGroup from './components/AdminDashboard/MainBody/AssignUserToGroup'
import Settings from './components/AdminDashboard/MainBody/Settings'
import UserTrips from './components/AdminDashboard/MainBody/UserTrips'
import UserTripsTest from './components/AdminDashboard/MainBody/UserTripsTest'
import TraceUser from './components/AdminDashboard/MainBody/TraceUser'
import TraceGroup from './components/AdminDashboard/MainBody/TraceGroup'
import TraceAllUsers from './components/AdminDashboard/MainBody/TraceAllUsers'
import ResetPassword from './pages/ResetPassword'
import UserRoutePointsCount from './components/AdminDashboard/MainBody/UserRoutePointsCount'

// Import Actions & Methods
import { tokenValidation } from './store/actions/authActions'
import DeleteUser from './components/AdminDashboard/MainBody/DeleteUser'

class App extends React.PureComponent {
  state = {
    isAuthenticated: false,
    isLoading: true
  }

  componentDidMount() {
    // Check If login token still valid
    const token = localStorage.getItem('token')

    if (token) {
      this.setState({ isLoading: true })

      // Validate Token
      tokenValidation(token)
        .then(isAuthenticated => {
          this.setState({ isAuthenticated, isLoading: false })
        })
        .catch(err => {
          console.error(err)
          this.setState({ isAuthenticated: false, isLoading: false })
        })

    } else {
      this.setState({ isAuthenticated: false, isLoading: false })
    }
  }

  render() {
    const { isAuthenticated, isLoading } = this.state
    const token = localStorage.getItem('token')
    const companyId = localStorage.getItem('companyId')

    return (
      <BrowserRouter>
        <Box {...appContainerStyles}>
          <Switch>
            <Route exact path='/'>
              {
                token && isAuthenticated && !isLoading ?
                  <Redirect to='/dashboard' /> :
                  isLoading ?
                    <CircularProgress /> :
                    <Redirect to='/login' />
              }
            </Route>

            <Route exact path='/login'>
              {
                token && isAuthenticated && !isLoading ?
                  <Redirect to='/' /> :
                  isLoading ?
                    <CircularProgress /> :
                    <TraceLogin />
              }
            </Route>

            <Route exact path='/reset-password'>
              {
                token && isAuthenticated && !isLoading ?
                  <Redirect to='/' /> :
                  isLoading ?
                    <CircularProgress /> :
                    <ResetPassword />
              }
            </Route>

            <Route exact path='/signup'>
              {
                token && isAuthenticated && !isLoading ?
                  <Redirect to='/' /> :
                  isLoading ?
                    <CircularProgress /> :
                    <CompanySignUp />
              }
            </Route>

            <ProtectedRoute
              exact={ true }
              path='/dashboard'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              {
                companyId === '11' ?
                  <Analytics />
                  :
                  <AnalyticsForServiq />
              }
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path='/add-user'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <AddUser />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/update-user'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <UpdateUser />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/delete-user'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <DeleteUser />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/create-group'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <CreateGroup />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/assign-user-to-group'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <AssignUserToGroup />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/settings'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <Settings />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/user-trips'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <UserTrips />
            </ProtectedRoute>

            {
              companyId === '11' &&
              <ProtectedRoute
                exact={ true }
                path='/user-trips-test'
                token={ token }
                isAuthenticated={ isAuthenticated }
                isLoading={ isLoading }
              >
                <UserTripsTest />
              </ProtectedRoute>
            }

            {
              companyId !== '11' &&
              <ProtectedRoute
                exact={ true }
                path='/route-points-count'
                token={ token }
                isAuthenticated={ isAuthenticated }
                isLoading={ isLoading }
              >
                <UserRoutePointsCount />
              </ProtectedRoute>
            }

            <ProtectedRoute
              exact={ true }
              path='/trace-user'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <TraceUser />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/trace-group'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <TraceGroup />
            </ProtectedRoute>

            <ProtectedRoute
              exact={ true }
              path='/trace-all-users'
              token={ token }
              isAuthenticated={ isAuthenticated }
              isLoading={ isLoading }
            >
              <TraceAllUsers />
            </ProtectedRoute>
          </Switch>
        </Box>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({ isSidePanelOpen: state.app.sidePanel.isSidePanelOpen })

// Protected Route
const ProtectedRoute = connect(mapStateToProps, null)(({ children, token, isAuthenticated, isLoading, isSidePanelOpen, ...restProps }) => {
  
  return (
    <Route {...restProps}>
      {
        token && isAuthenticated && !isLoading ?
          (
            <Box style={containerStyles}>
              <Menu />
                <Box
                  className='company-dashboard-body'
                  style={{
                    ...mainBodyStyles,
                    paddingLeft: (isSidePanelOpen ? '316px' : '16px'),
                    transitionProperty: 'padding-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
                    transitionDuration: '195ms',
                }}
                >
                  {children}
                </Box>
            </Box>
          )
          :
          isLoading ?
            <CircularProgress /> :
            <Redirect to='/login' />
      }
    </Route>
  )
  
})

// JSS Styles
const appContainerStyles = {
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden'
}

const containerStyles = {
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden'
}

const mainBodyStyles = {
  padding: '16px',
  // paddingLeft: '316px',
  width: '100%',
  height: '100%',
  background: '#f0f0f0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'auto',
}

export default App